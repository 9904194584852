import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Carousel from "../components/carousel/Carousel"

export default function Home() {
  //   const items = [
  //     "Design",
  //     "Architecture",
  //     "Construction",
  //     "Collaboration",
  //     "Administration",
  //   ]

  return (
    <Layout
      mainPage
      heroImage={
        // <StaticImage
        //   style={{
        //     gridArea: "1/1",
        //   }}
        //   layout="fullWidth"
        //   src="../images/home-hero.jpg"
        //   alt="Image of Erskinville roof."
        //   loading="eager"
        // />
        <Carousel interval={5000} transition={3000} />
      }
      heroMain={
        <>
          <StaticImage
            src="../images/logo-white-nopad.svg"
            alt="Dacca Architecture Logo"
            placeholder="blurred"
            className="w-[300px] z-30"
          />
          {/* <ul className="sm:absolute flex flex-col sm:flex-row bottom-12 space-y-4 sm:space-y-0 sm:space-x-4 text-center">
            {items.map(item => (
              <li className=" text-white uppercase text-base sm:text-xs md:text-base tracking-160">
                {item}
              </li>
            ))}
          </ul> */}
        </>
      }
      Seo
    />
  )
}
