import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function CarouselComponent(props) {
  const images = [
    <StaticImage
      src="../../images/home_carousel/Home_Caro_01.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
    <StaticImage
      src="../../images/home_carousel/Home_Caro_02.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
    <StaticImage
      src="../../images/home_carousel/Home_Caro_03.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
    <StaticImage
      src="../../images/home_carousel/Home_Caro_04.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
    <StaticImage
      src="../../images/home_carousel/Home_Caro_05.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
    <StaticImage
      src="../../images/home_carousel/Home_Caro_06.jpg"
      className="h-full"
      objectPosition="50% 70%"
      quality={80}
    />,
  ]

  const [active, setActive] = useState(
    props.forceActiveImage ? parseInt(props.forceActiveImage) : 0
  )
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false)

  useEffect(() => {
    if (props.forceActiveImage) return
    if (!props.cycle) return
    const timeout = setTimeout(() => {
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(props.transition)
        setActive(active === images.length - 1 ? 0 : active + 1)
        setFirstTransitionIsDone(true)
      }
      if (props.cycle) {
        startImageTransition()
      }
    }, props.interval)

    return () => clearTimeout(timeout)
  }, [active, firstTransitionIsDone, props])

  useEffect(() => {
    if (!props.forceActiveImage) return
    setActive(parseInt(props.forceActiveImage))
  }, [active, props.forceActiveImage])

  return (
    <div
      style={{
        gridArea: "1/1",
      }}
      className="relative"
    >
      <div className="absolute inset-0 z-[10] bg-black/15" />
      <div className="w-full h-full lg:h-[79vh] bg-slate-600/50 overflow-hidden relative">
        {images.map((image, index) => (
          <div
            key={index}
            className={`opacity-${
              active === index ? "100" : "0"
            } absolute h-full transition-opacity ease-in-out duration-[${
              props.transition
            }ms]`}
          >
            {image}
          </div>
        ))}
      </div>
    </div>
  )
}

CarouselComponent.defaultProps = {
  forceActiveImage: null,
  cycle: true,
  interval: 5000,
  transition: 5000,
}
